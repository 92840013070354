import styled from '@emotion/styled';
import { colors } from '../../styles/colors';
import { darken, desaturate, mix } from 'polished';

const ButtonLink = styled.a`
  display: inline-block;
  margin: 0 0 0 0;
  padding: 10px 20px;
  outline: none;
  color: #fff !important;
  text-decoration: none !important;
  font-size: 2rem;
  line-height: 37px;
  font-weight: 600;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  /* background: linear-gradient(
  color(var(--blue) whiteness(+7%)),
  color(var(--blue) lightness(-7%) saturation(-10%)) 60%,
  color(var(--blue) lightness(-7%) saturation(-10%)) 90%,
  color(var(--blue) lightness(-4%) saturation(-10%))
); */
  background: linear-gradient(
    ${mix('0.1', '#fff', colors.alpha)},
    ${desaturate('0.1', darken('0.07', colors.alpha))} 60%,
    ${desaturate('0.1', darken('0.07', colors.alpha))} 90%,
    ${desaturate('0.1', darken('0.04', colors.alpha))}
  );
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.14) !important;

  -webkit-font-smoothing: subpixel-antialiased;

  :hover {
    text-decoration: none;
  }

  :active,
  :focus {
    /* background: color(var(--blue) lightness(-9%) saturation(-10%)); */
    background: ${desaturate('0.1', darken('0.09', colors.alpha))};
  }
  @media (max-width: 500px) {
    margin: 10px 0 0;
    width: 100%;
  }
`;

export default ButtonLink;
